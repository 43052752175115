import React from 'react'
import './Footer.css'

export default () => (
<div>
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} | {' '}
          <a href="https://flordavidamarketing.com">Flor Da Vida Marketing Digital</a>.
        </span>
      </div>
    </footer>
  </div>

    /* Removed Intagram Feed on Fotter 

  import InstagramFeed from './InstagramFeed'
  
    <div>
    <h2 className="taCenter">
      Follow us{' '}
      <a href="https://instagram.com/thrivegoldcoast/">@thrivegoldcoast</a>
    </h2>
    <br />
    <InstagramFeed count="8" />
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Crafted by{' '}
          <a href="https://thriveweb.com.au/">Thrive</a>.
        </span>
      </div>
    </footer>
  </div>
  */
)
