import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

import PageHeader from '../components/PageHeader'
import FormContactAjax from '../components/FormContactAjax'
import Image from '../components/Image'
import Content from '../components/Content'
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'
import { faSearchLocation  } from '@fortawesome/free-solid-svg-icons'
import { faGoogle  } from '@fortawesome/free-brands-svg-icons'
import { faComments } from '@fortawesome/free-regular-svg-icons'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'


// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, address, phone, email, bodyImage, bodyText, teamImage1, teamImage2, teamImage3, teamImage4 }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      className="taCenter"
    >
      <button
        className='Button Form--SubmitButton'
        type='submit'
        value='redirected'
        style={{background: "transparent", border: "2px solid white", alignSelf: "center", color: "white", margin: "0 auto"}}
        onClick={() => scrollTo('#contato')}
      >FALE COM UM ESPECIALISTA
      </button>
    </PageHeader>

    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <Content source={bodyText} />
            <p style={{fontFamily:"Caveat", fontSize:"2em", fontWeight:"400", transform:"rotate(-5deg)", color:"#6a6a6a"}}>Time Flor da Vida Marketing Digital</p>
          </div>
          <div className="col-6" style={{float: "right", maxWidth: "50rem"}}>
            <Content source={bodyImage}/>
          </div>
        </div>
      </div>
    </section>

    <section className="section" style={{backgroundColor:"#f6f6f6"}}>
      <div className="container taCenter">
        <h2>NOSSO MARKETING</h2>
        <div className="row">
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
             <FontAwesomeIcon icon={faNetworkWired} size="3x" color="#444444" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>AGÊNCIA HOLÍSTICA</h3>
              <p>Como você já sabe, mudança de hábitos não ocorrem do dia para a noite. Implementar uma boa estratégia de marketing digital não é diferente. Portanto, permita-nos desenvolver uma estratégia completa para você, cada elemento no seu tempo.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
              <FontAwesomeIcon icon={faUsers} size="3x" color="#444444" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>FOCO NO SEU PÚBLICO</h3>
              <p>A sua marca deve falar a linguagem do seu público gerando credibilidade e interesse pelos seus serviços. Nossa agência cria experiências online digitais focadas nos seus clientes potenciais.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
            <FontAwesomeIcon icon={faTachometerAlt} size="3x" color="#444444"/>
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>MOVIDOS POR DADOS</h3>
              <p>Uma boa estratégia de marketing digital é aquela que funciona. Por isso usamos indicadores e métricas claras para medir o resultado de nossas ações de marketing digital otimizando seus resultados continuamente.</p>
            </div>
          </div>
        </div>      
      </div>
    </section>
    
    <section className="section" style={{backgroundColor:"#83254b", color:"white", borderBottom: "0px"}}>
      <div className="container taCenter">
        <h2>SERVIÇOS</h2>
        <p style={{paddingTop: "2rem"}}>Soluções completas para te ajudar a conseguir atrair e reter mais clientes.</p>
        <div className="row" style={{paddingTop: "2rem"}}>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
             <FontAwesomeIcon icon={faDesktop} size="3x" color="white" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>WEB DESIGN</h3>
              <p>Tenha um site bonito, informativo e eficiente.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
              <FontAwesomeIcon icon={faSearchLocation} size="3x" color="white" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>SEO</h3>
              <p>Apareça nos resultados das ferramentas de busca.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
            <FontAwesomeIcon icon={faGoogle} size="3x" color="white"/>
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>GOOGLE ADS</h3>
              <p>Seja encontrado por quem precisa dos seus serviços.</p>
            </div>
          </div>
        </div>      
      </div>
      <div className="container taCenter">
        <div className="row" style={{paddingTop: "2rem"}}>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
             <FontAwesomeIcon icon={faComments} size="3x" color="white" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>MÍDIAS SOCIAIS</h3>
              <p>Esteja presente no Facebook e Instagram.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
              <FontAwesomeIcon icon={faEdit} size="3x" color="white" />
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>CRIAÇÃO DE CONTEÚDO</h3>
              <p>Obtenha materiais personalizados para suas mídias.</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
            <FontAwesomeIcon icon={faLightbulb} size="3x" color="white"/>
            </div>
            <div style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>CONSULTORIA</h3>
              <p>Receba apoio para planejar e desenvolver tudo isso.</p>
            </div>
          </div>
        </div>      
      </div>
    </section>

    <div className="row" style={{backgroundColor:"#f6f6f6"}}>
      <div className="col-6 relative" style={{minHeight:"540px"}}>
        <Image
          background
          resolutions="large"
          src={featuredImage}
          alt={title}
          size="cover"
        />
      </div>
      <div className="col-6 section container">  
        <div style={{maxWidth:"33.75em", marginLeft:"auto", marginRight:"auto", width: "90%"}}>
          <h2>VAMOS EXPANDIR SEUS NEGÓCIOS JUNTOS?</h2>
          <p>Queremos a sua ajuda para proporcionar bem-estar e saúde para cada vez mais pessoas. Você cuida dos seus clientes, nós cuidamos de trazer eles até você!</p>
          <p>Para descobrir como podemos ajudar, entre em contato conosco para um bate-papo sem compromisso.</p>
          <button
            className='Button Form--SubmitButton'
            type='submit'
            value='redirected'
            style={{background: "transparent", border: "2px solid #444444", color: "#444444"}}
            onClick={() => scrollTo('#contato')}
          >FALE COM UM ESPECIALISTA
          </button>
        </div>
      </div>
    </div>

    <section className="section">
      <div className="container taCenter">
        <h2>CONHEÇA NOSSO TIME</h2>
        <div className="row" style={{paddingTop: "2rem"}}>
          <div className="col-3" style={{padding: "1rem"}}>
            <Content source={teamImage1}/>
            <div>
              <h3 style={{ marginTop:"-6rem", fontFamily:"Caveat", fontSize:"2em", fontWeight:"400", transform:"rotate(-5deg)", color:"#6a6a6a"}}>João Paulo Motta</h3>
              <h4 style={{ marginTop:"-2.5rem", fontWeight:"800"}}>DESENVOLVEDOR WEB</h4>
            </div>
          </div>
          <div className="col-3" style={{padding: "1rem"}}>
            <Content source={teamImage2}/>
            <div>
              <h3 style={{ marginTop:"-6rem", fontFamily:"Caveat", fontSize:"2em", fontWeight:"400", transform:"rotate(-5deg)", color:"#6a6a6a"}}>Rafael Giacomassi</h3>
              <h4 style={{ marginTop:"-2.5rem", fontWeight:"800"}}>CONSULTOR | MARKETING DIGITAL</h4>
            </div>
          </div>
          <div className="col-3" style={{padding: "1rem"}}>
            <Content source={teamImage3}/>
            <div>
              <h3 style={{ marginTop:"-6rem", fontFamily:"Caveat", fontSize:"2em", fontWeight:"400", transform:"rotate(-5deg)", color:"#6a6a6a"}}>Tiago Aude</h3>
              <h4 style={{ marginTop:"-2.5rem", fontWeight:"800"}}>ESPECIALISTA EM MÍDIAS</h4>
            </div>
          </div>
          <div className="col-3" style={{padding: "1rem"}}>
            <Content source={teamImage4}/>
            <div>
              <h3 style={{ marginTop:"-6rem", fontFamily:"Caveat", fontSize:"2em", fontWeight:"400", transform:"rotate(-5deg)", color:"#6a6a6a"}}>Leonardo Ricco</h3>
              <h4 style={{ marginTop:"-2.5rem", fontWeight:"800"}}>VENDAS</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
    <section id="contato" className="section Contact--Section1" style={{backgroundColor:"#83254b", color:"white"}}>
      <div className="container Contact--Section1--Container">
        <div>
          <h2>AGENDE UMA CONSULTA GRATUITA</h2>
          <p>Nosso time de especialistas está pronto para analisar o seu negócio e discutir soluções que caibam no seu bolso.</p>
          <div className="Contact--Details" style={{marginBottom:"3rem"}}>
            {address && (
              <a
                className="Contact--Details--Item"
                href={`https://www.google.com.au/maps/search/${encodeURI(
                  address
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapPin style={{color:"white"}}/> {address}
              </a>
            )}
            {phone && (
              <a className="Contact--Details--Item" href={`tel:${phone}`}>
                <Smartphone style={{color:"white"}}/> {phone}
              </a>
            )}
            {email && (
              <a className="Contact--Details--Item" href={`mailto:${email}`}>
                <Mail style={{color:"white"}}/> {email}
              </a>
            )}
          </div>
        </div>

        <div style={{marginTop:"-2.5rem"}}>
          <FormContactAjax name="Form Contact Ajax" />
        </div>
      </div>
    </section>

  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        bodyImage
        bodyText
        teamImage1
        teamImage2
        teamImage3
        teamImage4
        address
        phone
        email
      }
    }
  }
`
